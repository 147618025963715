import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { i18n, commonConfig, sessionStore, ConvertDate } from '../../common/commonIndex';
import Moment from 'react-moment';
import moment from 'moment';

function MarkersDynamicFields(props) {


    const generateCollpaseTitle = (data, type) => {
        let returndata = data && data.find(o => { return o.fieldName === type });
        return returndata && returndata.fieldValue ? returndata.fieldValue : commonConfig.DASH_VALUE
    }


    const generateFieldWiseView = (VIEW_DATA) => {
        return <div className="card-body pb-0">
            <div className="row mb-2">
                {
                    VIEW_DATA && VIEW_DATA.dynamicFormGroupControls && VIEW_DATA.dynamicFormGroupControls.length > 0 && VIEW_DATA.dynamicFormGroupControls.map((item, index) => {
                        return <div className="col-md-3 col-sm-6 col-12" key={index}>
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{(item.isMandatory || (props.componentTitle === "Student Information" && (item.fieldLabel === "Hispanic Or Latino" || item.fieldLabel === " Race"))) && <span> *</span>} </label>
                                <p>{item.fieldLabel === "Date Of Birth" ? moment(item.fieldValue).format(new sessionStore().returnDateFormat()) : item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                            </div>
                        </div>
                    })
                }
            </div>
            {VIEW_DATA && VIEW_DATA.studentContactDetails && VIEW_DATA.studentContactDetails.length > 0 && <div className="mb-2">
                {
                    VIEW_DATA.studentContactDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, "Student Relationship")}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentrela" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumentrela" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldLabel === "Date Of Birth" ? moment(item.fieldValue).format(new sessionStore().returnDateFormat()) : item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {add.phoneGroupDetails && add.phoneGroupDetails.length > 0 && <div className="mb-2">
                                        <div className="d-flex align-items-center mb-2">
                                            <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("phone")}</b></h5>
                                        </div>
                                        {
                                            add.phoneGroupDetails.map((add, phRel) => {
                                                return <div className="panel-default" key={phRel}>
                                                    <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, props.componentTitle === "Student Contacts" ? "Phone Type Student Contact" : "Phone Type")}</b></h5>
                                                            </div>
                                                            <div className="text-nowrap">
                                                                <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentphonrela" + phRel + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="collapse" id={"collapseDocumentphonrela" + phRel + addIndex}>
                                                        <div className="panel-body pt-0">
                                                            <div className="row">
                                                                {
                                                                    add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                                        return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                                            <div className="form-group">
                                                                                <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                                                <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>}

                                    {add.addressGroupDetails && add.addressGroupDetails.length > 0 && <div className="mb-2">
                                        <div className="d-flex align-items-center mb-2">
                                            <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("address")}</b></h5>
                                        </div>
                                        {
                                            add.addressGroupDetails.map((add, addrel) => {
                                                return <div className="panel-default" key={addrel}>
                                                    <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, props.componentTitle === "Student Contacts" ? "Address Type Student Contact" : "Address Type")}</b></h5>
                                                            </div>
                                                            <div className="text-nowrap">
                                                                <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentaddressrela" + addrel + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="collapse" id={"collapseDocumentaddressrela" + addrel + addIndex}>
                                                        <div className="panel-body pt-0">
                                                            <div className="row">
                                                                {
                                                                    add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                                        return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                                            <div className="form-group">
                                                                                <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                                                <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>}
                                </div>
                            </div>




                        </div>
                    })
                }
            </div>}
            {VIEW_DATA && VIEW_DATA.phoneGroupDetails && VIEW_DATA.phoneGroupDetails.length > 0 && <div className="mb-2">
                <div className="d-flex align-items-center mb-2">
                    <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("phone")}</b></h5>
                </div>
                {
                    VIEW_DATA.phoneGroupDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, props.componentTitle === "Student Contacts" ? "Phone Type Student Contact" : "Phone Type")}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentphone" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumentphone" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}

            {VIEW_DATA && VIEW_DATA.addressGroupDetails && VIEW_DATA.addressGroupDetails.length > 0 && <div className="mb-2">
                <div className="d-flex align-items-center mb-2">
                    <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("address")}</b></h5>
                </div>
                {
                    VIEW_DATA.addressGroupDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, props.componentTitle === "Student Contacts" ? "Address Type Student Contact" : "Address Type")}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentadd" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumentadd" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}

            {VIEW_DATA && VIEW_DATA.supportDocumentDetails && VIEW_DATA.supportDocumentDetails.length > 0 && <div className="mb-2">
                <div className="d-flex align-items-center mb-2">
                    <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("supportDocument")}</b></h5>
                </div>
                {
                    VIEW_DATA.supportDocumentDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{"title"}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentsupp" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumentsupp" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}

            {VIEW_DATA && VIEW_DATA.healthInsuranceDetails && VIEW_DATA.healthInsuranceDetails.length > 0 && <div className="mb-2">
                <div className="d-flex align-items-center mb-2">
                    <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("healthInsurance")}</b></h5>
                </div>
                {
                    VIEW_DATA.healthInsuranceDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, "Insurance Name")}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumenthealth" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumenthealth" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}

            {VIEW_DATA && VIEW_DATA.medicationDetails && VIEW_DATA.medicationDetails.length > 0 && <div className="mb-2">
                <div className="d-flex align-items-center mb-2">
                    <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{i18n.t("medicationDetails")}</b></h5>
                </div>
                {
                    VIEW_DATA.medicationDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{"title"}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentmed" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumentmed" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}
            {VIEW_DATA && VIEW_DATA.identifierGroupDetails && VIEW_DATA.identifierGroupDetails.length > 0 && <div className="mb-2">
                {
                    VIEW_DATA.identifierGroupDetails.map((add, addIndex) => {
                        return <div className="panel-default" key={addIndex}>
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <h5 className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{generateCollpaseTitle(add.dynamicFormGroupControls, 'System Name')}</b></h5>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#collapseDocumentiden" + addIndex} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={"collapseDocumentiden" + addIndex}>
                                <div className="panel-body pt-0">
                                    <div className="row">
                                        {
                                            add && add.dynamicFormGroupControls && add.dynamicFormGroupControls.length > 0 && add.dynamicFormGroupControls.map((item, index) => {
                                                return <div className="col-md-3 col-sm-6 col-12" key={index}>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t(item.fieldLabel ? item.fieldLabel : commonConfig.DASH_VALUE)}{item.isMandatory && <span> *</span>}</label>
                                                        <p>{item.fieldValue ? item.fieldValue : commonConfig.DASH_VALUE}</p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}

            {VIEW_DATA && VIEW_DATA.immunizationDetails && VIEW_DATA.immunizationDetails.length > 0 && <div className="mb-2">
                {
                    VIEW_DATA.immunizationDetails.map((item, key) => {
                        return <div className="panel-default">
                            <div className="collapse-panel-default panel-header p-t-8 p-b-8 m-b-3 m-t-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <span className="text-primary m-b-3 m-t-3 mr-2 f-s-16"><b>{item.immunizationNameTitle}</b>
                                        </span>
                                        <div><i className="icon icon-syringe2"></i> <span className="badge badge-warning">{item.takenDoses}/{item.totalDoses}</span></div>
                                        <label class="badge badge-success ml-3 mb-1">{item.immunizationStatus}</label>
                                    </div>
                                    <div className="text-nowrap">
                                        <a data-toggle="collapse" className="text-muted collapsed" href={"#colla" + key} aria-expanded="false"><i className="icon-menu-open" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id={'colla' + key}>
                                <div className="panel-body pt-0 pb-0">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-form-label">{i18n.t('immunizationName')}<span> *</span></label>
                                                <p>{item.immunizationNameTitle}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-form-label">{i18n.t('status')}<span> *</span></label>
                                                <p>{item.immunizationStatus}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-form-label">{i18n.t('exemptionDate')}</label>
                                                <p>{!!item.exemptionDate ? <Moment format={new sessionStore().returnDateFormat()}>{item.exemptionDate}</Moment> : commonConfig.DASH_VALUE}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="col-form-label">{i18n.t('diseaseIllnessDate')}</label>
                                                <p>{!!item.diseaseIllnessDate ? <Moment format={new sessionStore().returnDateFormat()}>{item.diseaseIllnessDate}</Moment> : commonConfig.DASH_VALUE}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-default">
                                        <div className="panel-body">
                                            <h5 className="f-s-18 m-b-15"><b>{i18n.t('doseDetails')}</b></h5>
                                            <div className="panel-default b-t-0">
                                                <div className="panel-body p-0">
                                                    <div className="table-responsive">
                                                        {!!item.dosageDetails && item.dosageDetails.length > 0 &&
                                                            <table className="table m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th><b>{i18n.t('dose')}</b></th>
                                                                        <th><b>{i18n.t('immunizationDate')}</b></th>
                                                                        {item.immunizationName === 1 &&
                                                                            <th><b>{i18n.t('tdap')}</b></th>
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.dosageDetails.sort(function (a, b) {
                                                                        return a.id - b.id
                                                                    }) && item.dosageDetails.map((value, key) => {
                                                                        return <tr>
                                                                            <td><Link to="#" data-toggle="modal">{i18n.t('dose') + (key + 1)}</Link></td>
                                                                            <td>{!!value.immunizationDate && <Moment format={new sessionStore().returnDateFormat()}>{value.immunizationDate}</Moment>}</td>
                                                                            {item.immunizationName === 1 &&
                                                                                <td><label className="checkbox-block mb-2"><input type="checkbox" name="isTDAP" checked={value.isTDAP} /><span className="checkmark"></span></label></td>
                                                                            }
                                                                        </tr>
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <MarkersCommonFields menuCode={commonConfig.STUIMMU_MENUCODE} show={commonConfig.CUSTOMFIELD_VIEW} submitFlag={false} redirectUrl="" updateFieldData='' fields='' isError={0} isRepeat={0} entityId={id} /> */}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>}
        </div>
    }


    const { headerTitle, mode, VIEW_DATA } = props;

    return (
        <React.Fragment>
            <div className="card mb-3">
                {headerTitle && <div className="card-header">
                    <h5 className="card-title text-primary">{i18n.t(headerTitle)}</h5>
                </div>}
                {mode === "VIEW" && generateFieldWiseView(VIEW_DATA)}
            </div>

        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
    };
}
const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersDynamicFields);