import { Route } from 'react-router-dom';

export class SharedApiConfig extends Route {
    static JSON_CONTENT_TYPE_HEADER = { 'Content-Type': 'application/json' };
    static FILE_CONTENT_TYPE_HEADER = { 'Content-Type': 'multipart/form-data' };
        //{
        //'Content-Type': undefined,//'multipart/form-data'
        //"Access-Control-Allow-Origin": "*",
        //"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        //"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token" };
    static UNICODED_CONTENT_TYPE_HEADER = { 'Content-Type': 'application /x-www-form-urlencoded' };

    static API_TO_CAll = 'api/v1/ApiExecution/execute';
    static API_TO_UPLOAD_AND_SAVE = 'api/v1/ApiExecution/executeuploadandsave';
    static API_TO_CAll_VALIDATION = 'api/v1/ApiExecution/authenticate';
    static API_TO_CAll_FOR_LOGIN_URL = 'api/v1/ApiExecution/getloginurl'
}