import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { i18n,  sessionStore } from './commonIndex';
import invertedLogo from '../../assets/images/logo-inverted.png';
import '../../assets/css/applicant-style.css';
const initLogFields = {
    logoutStatus:false
}
function SideBranding(props) {
    const [logState, logStateObj] = useState(initLogFields);
    const [URI_PARAMS, setUriParams] = useState("");
    useEffect(() => {
        window.$("body").addClass("application-form");
    }, [])
    //Logout function.
    const handleLogout = (e) => {
        e.preventDefault();
        let URL = new sessionStore().returnApplicantLoginUrl();
        setUriParams(URL);
        logStateObj({ ...logState, logoutStatus: true });
    }
    if (logState.logoutStatus) {
        var n = sessionStorage.length;
        while (n--) {
            var key = sessionStorage.key(n);
            new sessionStore().cleanSessionStore(key);
        }
        let path = location.protocol + "//" + location.host + "/applyOnline?" + URI_PARAMS
        window.location.href = path;
    }
    return (
        <React.Fragment>
            <div className="brand-left-wrap">
                
                <div className="branding-wrap d-flex align-items-center justify-content-between mb-2">
                        <div className="brand-logo">
                            <img src={invertedLogo} width="60px " alt="Generic Placeholder Image" />
                        </div>
                        {/*<h1 className="mb-4 text-white">{i18n.t('welcome')}</h1>
                        <div className="text-white">
                            <p>Welcome to our online application process. Please provide authentic and verified information in the required fields to proceed.</p>
                        </div>*/}
                        <div className="">
                            {new sessionStore().returnApplicantId() ?
                                <button className="btn bg-white box-shadow-0 p-l-25 p-r-25" onClick={(e) => handleLogout(e)}><b>{i18n.t('logout')}</b></button> :
                                <button className="btn bg-white box-shadow-0 p-l-25 p-r-25"><b>{i18n.t('returnInitialPage')}</b></button>
                            }
                        </div>
                    </div>
                
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {

    };
}
const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBranding);