import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../errorboundary/ErrorBoundary';
import { i18n } from '../commonIndex';
import _ from 'lodash';
import PrivateRoute from '../../../security/PrivateRoute';
class ApplicantDashBoardLayoutComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
    }
    render() {

        return (
            <React.Fragment>

                {/* <!-- Content Wrapper area start--> */}
                <ErrorBoundary>
                    <div className="general-loader">
                        <span>
                            <div className="loader-N">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 296.881 258.606">
                                    <g id="Group_159" transform="translate(-0.001 -0.001)">
                                        <path id="Path_1" d="M651.855,744.721l-63.137,40.948L440.28,689.639v161.22h54.792V790.242l93.534,60.664.111-.171.111.171,63.026-40.876Z" transform="translate(-440.275 -592.299)" fill="#0e67b2" />
                                        <path id="Path_2" d="M588.721,748.516l-148.331-96.2-.111.171V717.55l148.331,96.2.111-.172.111.172,93.535-60.664V910.9H737.16V652.491Z" transform="translate(-440.278 -652.315)" fill="#001e33" />
                                    </g>
                                </svg>
                            </div>
                        </span>
                    </div>

                    {/* <!-- Lazy loading routes --> */}
                    <PrivateRoute />
                </ErrorBoundary>
                {/* Content Wrapper area end */}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
    };
}
const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicantDashBoardLayoutComponent);
