export class endPointConfig { 
    static COMPONENT_SAVE_SIBLING = 'v1/applicantforms/partialsaveapplicantformswithfiles';
    //fees
    static COMPONENT_ASSOCIATED_FEES = 'v1/applicationformsdetailwisefee/getassociatedapplicationformsfee?applicationFormIds=';
    static COMPONENT_ADMIN_FEE_FORMWISE_LIST = 'v1/applicationformsdetailwisefee/getapplicationformsdetailwisefee?applicationFormIds=';
    static COMPONENT_ADMIN_FEE_SAVEFEESETUP = 'v1/applicationformsdetailwisefee/saveapplicationwisefeesetup';
    static COMPONENT_INTEGRATION_MP_SCHOOL_LIST = "v1/integrationmarkarspro/getmarkersproschoollist?customerId=";
    static COMPONENT_INTEGRATION_MP_SESSION_LIST = "v1/integrationmarkarspro/getmarkersprosessionlist?customerId=";
    static COMPONENT_INTEGRATION_MP_GRADE_LEVEL_LIST = "v1/integrationmarkarspro/getmarkersgradelevellist?customerId=";
    static COMPONENT_INTEGRATION_MP_FEE_STRUCTURE = "v1/integrationmarkarspro/getmarkersprofeestructure?customerId=";
    static COMPONENT_ADMIN_GET_FEE_GROUP_LIST = "v1/feesgroup/getallfeesgroup";
    static COMPONENT_ADMIN_FEE_GROUP_SAVE = "v1/feesgroup/savefeesgroup";
    static COMPONENT_ADMIN_FEE_GROUP_UPDATE = "v1/feesgroup/updatefeesgroup";
    static COMPONENT_ADMIN_GET_FEE_GROUP_BY_ID = "v1/feesgroup/getfeesgroupbyid?id=";
    static COMPONENT_ADMIN_FEE_GROUP_DELETE = "v1/feesgroup/deletefeesgroup";
    static COMPONENT_GATEWAY_INTEGRATION_GET_BY_ID = 'v1/seagatewayintegration/getseagatewayintegrationbyid?id=';
    static COMPONENT_SAVE_GATEWAY_INTEGRATION = 'v1/seagatewayintegration/saveseagatewayintegration';
    static COMPONENT_EDIT_GATEWAY_INTEGRATION = 'v1/seagatewayintegration/updateseagatewayintegration';
    static COMPONENT_GATEWAY_ALL_INTEGRATION_LIST = 'v1/seagatewayintegration/getallseagatewayintegration?CustomerId=';
    static GET_GATEWAY_LIST_BY_CUSTOMER_ID = "v1/seagatewayintegration/getallseagatewayintegration?CustomerId=";
    static GET_ALL_ACTIVE_GATEWAY_LIST_BY_CUSTOMER_ID = "v1/seagatewayintegration/getallactiveseagatewayintegration?CustomerId="
    static COMPONENT_RAZOR_STRIPE_CHECKOUT_PAYMENT = 'v1/paymentgateway/checkoutpayment';
    static COMPONENT_GENERATE_ATOM_GATEWAY = 'v1/atompayment/generateatompaymentgateway';
    static SAVE_APPLICANT_ONLINE_PAYMENT = "v1/applicantpayment/saveapplicanttotalonlinepayment";
    //GRADELEVEL
    static COMPONENT_ENROLLMENT_SAVE_GRADE_LEVEL = 'v1/gradelevel/savegradelevel';
    static COMPONENT_ENROLLMENT_UPDATE_GRADE_LEVEL = 'v1/gradelevel/updategradelevel';
    static COMPONENT_ENROLLMENT_GET_GRADE_LEVEL_BY_ID = 'v1/gradelevel/getgradelevelbyid?id=';
    static COMPONENT_ENROLLMENT_DELETE_GRADE_LEVEL = 'v1/gradelevel/deletegradelevel';
    static COMPONENT_ENROLLMENT_GET_ALL_GRADE_LEVEL = 'v1/gradelevel/getallgradelevel?showOnlyActive=';
    static COMPONENT_ENROLLMENT_GET_ALL_MP_GRADE_LEVEL = 'v1/gradelevel/getallgradelevelbympschoolid?mpSchoolId=';
    static COMPONENT_ENROLLMENT_UPDATE_AGE_CRITERIA= 'v1/gradelevel/updategradeagecriteria';
    //lottery
    static COMPONENT_SAVE_LOTTERY_WEIGHT = 'v1/applicantforms/updatelotteryweightage';
    static COMPONENT_SAVE_LOTTERY_Exempt = 'v1/applicantformlotteryexemptionlist/saveapplicantformlotteryexemptionlist';
    static COMPONENT_CATEGORY_LIST = "v1/applicationformlotteryseatsexemption/getbyapplicationformlotteryseatsid?applicationFormLotterySeatsId="
    static GET_ALL_APPLICATIONFORM_LOTTERY_SETUP = "v1/applicationformlottery/getallapplicationformlotterysetup?ApplicationFormId=";
    static GET_ALL_APPLICATIONFORM_LOTTERY_SETUP_PAGINATE = "v1/applicationformlottery/getallapplicationformlotterysetuppagination?ApplicationFormId=";
    static GET_APPLICATIONFORM_LOTTERY_DETAILS = "v1/applicationformlottery/getapplicationformlotterybyid?id=";
    static GET_APPLICATIONFORM_GARDELEVELS = "v1/applicationformgradelevel/getallgradelevels?ApplicationFormId=";
    static SAVE_LOTTERY_SETUP_ADMIN = "v1/applicationformlottery/saveapplicationformlottery";
    static UPDATE_LOTTERY_SETUP_ADMIN = "v1/applicationformlottery/updateapplicationformlottery";
    static DELETE_LOTTERY_SETUP_ADMIN = "v1/applicationformlottery/deleteapplicationformlottery";
    static GET_CONDUCTED_LOTTERY = "v1/applicantformlotterygradelevelresults/getconductlottery";
    static GET_GRADE_WISE_PROCESSING_RESULT = "v1/applicantformlotterygradelevelresults/gradewiselotteryprocessingresult?";
    static GET_GRADE_LEVEL_BY_APPLICATIONFORMID = "v1/applicationform/getgradelevelbyapplicationformid?formId=";
    static GET_LOTTERYWISE_APPICANT_LIST = "v1/applicationformlottery/getlotterydetailapplicantwise?";
    static GET_LOTTERY_GRADELEVEL = "v1/applicationformgradelevel/getgradelevelassociatewithlottery?";
    static SAVE_LOTTERY_CONDUCT = "v1/applicationformlotteryconduct/lotteryconductprocess?";
    static UPDATE_LOTTERY = "v1/applicantformlotterygradelevelresults/updateisrejecetedispromotedaslotterywinner?";
    static COPY_SIBLING_DATA = "v1/applicantforms/copysiblingdata";
    static CONDUCT_LOTTERY_EMAIL_NOTIFICATION = "v1/applicationformlotteryconduct/sendemailforlotteryresult?"
    //static COMPONENT_GATEWAY_TYPE_LIST = '';
    static COMPONENT_GATEWAY_INTEGRATION_DELETE = 'v1/seagatewayintegration/deleteseagatewayintegration';
    static COMPONENT_FEE_CURRENCY_CODE = 'v1/country/getcurrencylist'
    static COMPONENT_APPLICATION_FEESET_DELETE = 'v1/feesetup/deletefeesetup';
    static COMPONENT_EDIT_DYNAMIC_FEE_SET = 'v1/feesetup/updatefeesetup';
    static COMPONENT_SAVE_DYNAMIC_FEE_SET = 'v1/feesetup/savefeesetup';
    static COMPONENT_SAVE_DYNAMIC_FEE_SET_IMPORT = 'v1/feesetup/savefeesetuplist';
    static COMPONENT_FEE_SET_GET_BY_ID = 'v1/feesetup/getfeesetupbyid?id=';
    static COMPONENT_ADMIN_EDIT_FEE_GROUP_PAGINATION = "v1/feesgroup/getallfeesetuppagination?id=";
    static COMPONENT_ENROLLMENT_GET_DYNAMIC_FEE_SET = 'v1/feesgroup/getallfeesetup?';
    static COMPONENT_ENROLLMENT_GET_DYNAMIC_FEE_SET_PAGINATE = 'v1/feesetup/getAllFeeSetUpPagination?';
    static COMPONENT_ENROLLMENT_GET_ONLY_DYNAMIC_FEE_SET = 'v1/feesetup/getallfeesetup?feeGroupId=';
    static COMPONENT_APPLICATION_FORMSET_DELETE = 'v1/dynamicformset/deletedynamicformset';
    static COMPOENT_ADMIN_MP_GET_IMPORTED_FEE = 'v1/feesetup/getallimportedfeesetup?schoolId=';
    static COMPONENT_APPLICANT_GRADE_LEVEL_BY_APPLICATIONFORMID = 'v1/applicationform/getgradelevelbyapplicationformid?formId=';
    //Admin Form Creation
    static COMPONENT_ADMIN_GENERIC_FORM_SET_UP_FORM_SET_LIST_GET_BY_APPLICATION_FORM_ID = 'v1/dynamicformset/getdynamicformsetbyapplicationformid?applicationFormId=';
    //static COMPONENT_APPLICATION_FORMSET_EDIT = 'v1/dynamicformset/updatedynamicformset';
    static COMPONENT_ADMIN_SAVE_FORM_SET_SORT_GLOBAL = "v1/formsetsortorder/saveformsetsortorder";
    static COMPONENT_APPLICATION_FORM_SORT_MODAL_BY_ID = 'v1/dynamicform/getdynamicformsbyapplicationformid?applicationFormId=';
    static COMPONENT_APPLICATION_FORMSET_DELETE = 'v1/dynamicformset/deletedynamicformset';
    static COMPONENT_FORM_SET_GET_BY_ID = "v1/dynamicformset/getdynamicformsetbyid?id=";
    static COMPONENT_SAVE_DYNAMIC_FORM_SET = 'v1/dynamicformset/savedynamicformset';
    static COMPONENT_EDIT_DYNAMIC_FORM_SET = 'v1/dynamicformset/updatedynamicformset';
    static COMPONENT_ENROLLMENT_GET_DYNAMIC_FORM_SET = "v1/dynamicformset/getdynamicformsetbyapplicationformid?applicationFormId="
    static COMPONENT_ENROLLMENT_GET_ACTIVE_FORM_SET = "v1/dynamicformset/getactivedynamicformsetbyapplicationformid?applicationFormId="
    static COMPONENT_ENROLLMENT_GET_ACTIVE_DYNAMIC_FORM_SET_LOTTERY = "v1/dynamicform/getdynamicformsforlottery?applicationFormId="
    static COMPONENT_ENROLLMENT_GET_ACTIVE_DYNAMIC_FORM_SET = "v1/dynamicformset/getactivedynamicformsetbyapplicationformid?applicationFormId="
    static COMPONENT_ADMIN_DYNAMIC_FORM_GET_ALL = 'v1/dynamicform/getdynamicformsbycustomerid?';
    static COMPONENT_EXISTING_APPLICATION_APPLICANT_GET_BY_ID = 'v1​/applicantforms​/getexistingapplicationsbyapplicantid?';
    static COMPONENT_ADMIN_DYNAMIC_FORM_GET_BY_ID = 'v1/dynamicform/getdynamicformbyid?';
    static COMPONENT_ADMIN_DYNAMIC_FORM_SAVE = 'v1/dynamicform/savedynamicform';
    static COMPONENT_ADMIN_DYNAMIC_FORM_UPDATE = 'v1/dynamicform/updatedynamicform';
    static COMPONENT_ADMIN_DYNAMIC_FORM_UPDATE_V2 = 'v1/applicationform/updateformtype';
    static COMPONENT_ADMIN_DYNAMIC_FORM_DELETE = 'v1/dynamicform/deletedynamicform';
    static COMPONENT_ADMIN_DYNAMIC_FORM_GET_BY_APPLICATIONFORMID = 'v1/dynamicform/getdynamicformsbyapplicationformid?applicationFormId='
    static COMPONENT_ADMIN_DYNAMIC_FORM_FIELDS_DETAILS_BY_ID = 'v1/dynamicform/getdynamicformfieldsdetails?dynamicFormId=';
    static COMPONENT_ADMIN_APPLICANT_FORMWISE_DYNAMIC_LIST = "v1/dynamicformassociate/applicationformwisedynamiclist?applicationformIds=";
    //System Identification
    static COMPONENT_ADMIN_SYSTEM_IDENTIFICATION_GET_BY_ID = 'v1/integrationdata/getintegrationbyid?id=';
    static COMPONENT_ADMIN_SYSTEM_IDENTIFICATION_GET_ALL = 'v1/integrationdata/getallintegration';
    static COMPONENT_ADMIN_SYSTEM_IDENTIFICATION_SAVE = 'v1/integrationdata/saveintegration';
    static COMPONENT_ADMIN_SAVE_DYNAMIC_FORM_SET = "v1/dynamicformset/savedynamicformset";
    static COMPONENT_ADMIN_SYSTEM_IDENTIFICATION_UPDATE = 'v1/integrationdata/updateintegration';
    static COMPONENT_ADMIN_SYSTEM_IDENTIFICATION_DELETE = 'v1/integrationdata/deleteintegrationbyid';
    static COMPONENT_STUDENT_IMMUNIZATION_NAME_GETALL = 'v1/applicantimmunization/getimmunizationnames';
    static COMPONENT_APPLICANT_IMMUNIZATION_INFO_SAVE = 'v1/applicantimmunization/saveimmunizationinfo';
    static COMPONENT_APPLICANT_IMMUNIZATION_INFO_UPDATE = "v1/applicantimmunization/updateimmunizationinfo";
    static COMPONENT_APPLICANT_IMMUNIZATION_GET_ALL = 'v1/applicantimmunization/getstudentimmunizations?applicantFormId='
    //Application Form
    static COMPONENT_FORM_GET = 'v1/dynamicformassociate/getapplicationformsbyfilter?'
    static COMPONENT_APPLICATION_FORM_GET_ALL = 'v1/applicationform/getactiveapplicationformsbycustomerid?';
    static COMPONENT_ADMIN_APPLICATION_FORM_GET_ALL = 'v1/applicationform/getapplicationformsbycustomerid?';
    static COMPONENT_ADMIN_APPLICATION_FORM_GET_ALL_DATA = 'v1/applicationform/getallapplicationform?';
    static COMPONENT_ADMIN_APPLICATION_FORM_GET_ALL_DATA_PAGINATE = 'v1/applicationform/getallapplicationformpagination?';
    static COMPONENT_APPLICATION_FORM_GET_BY_ID = 'v1/applicationform/getapplicationformbyid?';
    static COMPONENT_APPLICATION_FORM_SAVE = 'v1/applicationform/saveapplicationform';
    static COMPONENT_APPLICATION_FORM_UPDATE = 'v1/applicationform/updateapplicationform';
    static COMPONENT_APPLICATION_FORM_DELETE = 'v1/applicationform/deleteapplicationform';
    static COMPONENT_APPLICATION_FORM_COPY = 'v1/applicationform/copyapplicationform?formId=';
    static COMPONENT_GET_ALL_GRADE_LEVEL_LIST_SCHOOL_WISE = 'v1/applicationformgradelevel/getallgradelevelsschoolwise?SchoolName=';
    static COMPONENT_GET_ALL_GRADE_LEVEL_LIST_SCHOOL_WISE_UPDATED = 'v1/integrationmarkarspro/getmarkersgradelevellist?customerId=';
    static COMPONENT_GET_ALL_GRADE_LEVEL_LIST_SCHOOL_WISE_NEW = 'v1/applicationformgradelevel/getallgradelevelnameschoolwise?SchoolName=';
    static COMPONENT_ENROLLMENT_APPLICATIONFORM_SCHOOLLIST = 'v1/applicationform/getapplicationformschoollist?customerId=';
    static COMPONENT_ENROLLMENT_APPLICATIONFORM_SESSIONLIST = 'v1/applicationform/getapplicationformsessionlist?customerId=';
    static COMPONENT_ENROLLMENT_APPLICATIONFORM_GRADELEVEL = "v1/gradelevel/getallgradelevel?showActiveOnly=true";
    //Applicant Dynamic Form
    static COMPONENT_APPLICANT_GET_FORM_DETAILS = 'v1/applicantforms/getapplicationforminfodetails?applicationFormId=';
    static COMPONENT_APPLICANT_GENERATE_DOC = "v1/applicantforms/generatestudentinfodoc?";
    //Applicant 
    static COMPONENT_DYNAMIC_FORMS = 'v1/dynamicformset/getdynamicformsetwisedynamicform?applicationFormId=';
    static COMPONENT_APPLICANT_GENERATE_CODE = "v1/applicantauthorization/generateauthenticationcode";
    static COMPONENT_APPLICANT_VALIDATE_CODE = "v1/applicantauthorization/validateapplicantcode";
    static COMPONENT_APPLICANT_USER_CONFORMATION = "v1/integrationmarkarspro/getsismarkersprocustomerdetails?"
    static COMPONENT_APPLICANT_PARTIAL_FORM_SAVE = "v1/applicantforms/partialsaveapplicantforms";
    static COMPONENT_APPLICANT_PARTIAL_FILE_FORM_SAVE = "v1/applicantforms/partialsaveapplicantformswithfiles";
    static COMPONENT_APPLICANT_PARTIAL_MULTI_FILES_FORM_SAVE = "v1/applicantforms/PartialSaveApplicantFormsWithMultipleFiles"
    static COMPONENT_APPLICANT_FORM_DATA_DELETE = "v1/applicantforms/deleteapplicantformgroupdata";
    static COMPONENT_DELETE_APPLICANT_PHOTO = 'v1/fileinfo/deleteuploadedfile';
    static COMPONENT_DOWNLOAD_FILE = 'v1/fileinfo/downloadfile?fileInfoId=';
    static COMPONET_SIS_FILE_DOWNLOAD = 'v1/fileinfo/SISFileDownload?fileInfoId=';
    static COMPONENT_ADMIN_SAVE_FEE = "v1/applicantformfees/saveapplicantformfeelist";
    //Dynamic Form Association
    static COMPONENT_APPLICATION_FORM_GET_BY_FILTER = 'v1/dynamicformassociate/getapplicationformsbyfilter?';
    static COMPONENT_ASSOCIATED_DYNAMIC_FORM_GET_ALL = 'v1/dynamicformassociate/getdynamicformsbycustomerid?';
    static COMPONENT_DYNAMIC_FORM_ASSOCIATION_SAVE = 'v1/dynamicformassociate/savedynamicformassociation';
    static COMPONENT_ASSOCIATED_FORM_GET_BY_APPLICATION_ID = 'v1/dynamicformassociate/getassociatedformsbyapplicationformid?';
    static COMPONENT_APPLICATION_FORM_GET_BY_FILTER = "v1/dynamicformassociate/getapplicationformsbyfilter?";
    static COMPONENT_ASSOCIATED_DYNAMIC_FORM_BY_ID = 'v1/dynamicformassociate/getassociateddynamicformbyid?';
    static COMPONENT_ASSOCIATED_FORM_DELETE = 'v1/dynamicformassociate/deletedynamicformassociationbyid';
    //Dynamic Form Setup
     static COMPONENT_APPLICATION_DYNAMIC_FORM_COPY = 'v1/dynamicform/copydynamicform?dynamicFormId=';
    static COMPONENT_ADMIN_DYNAMIC_FORM_SETUP_GET_BY_DYNAMICFORMID = 'v1/dynamicformsetup/getdynamicformsetupbydynamicformid?dynamicFormId='
    static COMPONENT_ADMIN_DYNAMIC_FORM_SETUP_SAVE = 'v1/dynamicformsetup/savedynamicformsetup'
    static COMPONENT_ADMIN_DYNAMIC_FORM_SETUP_UPDATE = 'v1/dynamicformsetup/updatedynamicformsetup'
    static COMPONENT_ADMIN_DYNAMIC_FORM_SETUP_DELETE = 'v1/dynamicformsetup/deletedynamicformfieldbyid'
    static COMPONENT_ADMIN_DYNAMIC_FORM_SETUP_COPY = 'v1/dynamicformsetup/copydynamicformsetup?dynamicFormFieldId='
    //Applicant from
    static COMPONENT_APPLICANT_ADMIN_STATUS = 'v1/statusmessage/getstatusbycustomerid?';
    static COMPONENT_APPLICANT_FORM_GET_ALL = 'v1/applicantforms/getallapplicantlists';
    static COMPONENT_APPLICANT_FORM_GET_ALL_PAGINATE = 'v1/applicantforms/getallapplicantlistspagination';
    static COMPONENT_APPLICANT_REGISTERED_FORM_GET_ALL = 'v1/integrationmarkarspro/getsismarkersprocustomerdetails?';
    static COMPONENT_APPLICANT_PARENT_VERIFICATION_COPY_PREVIOUS_STUDENT = 'v1/applicantparentverification/copypreviousstudent';
    //status message
    static COMPONENT_UPDATE_STATUS_MESSAGE = 'v1/statusmessage/updatestatusmessage';
    static COMPONENT_APPLICANT_MESSAGE_READ_UPDATE = "v1/applicantformmessagelog/updateisreadbyapplicant?"
    //MarkersPro Customer Integration
    static COMPONENT_MARKERSPRO_SCHOOL_GET_ALL = 'v1/integrationmarkarspro/getmarkersproschoollist?';
    static COMPONENT_MARKERSPRO_SESSION_GET_ALL = 'v1/integrationmarkarspro/getmarkersprosessionlist?';
    static COMPONENT_MARKERSPRO_GRADE_LEVEL_GET_ALL = 'v1/integrationmarkarspro/getmarkersgradelevellist?';
    //Non MarkersPro Customer Integration
    static COMPONENT_NON_MARKERSPRO_SCHOOL_GET_ALL = 'v1/applicationform/getdistinctschoolnamebycustomerid?';
    static COMPONENT_FORM_GET_NON_MARKERSPRO = 'v1/applicationform/getapplicationformsbyschoolname?'
    //message log for admin and applicant
    static COMPONENT_SYSTEM_MESSAGE_LOG = 'v1/applicantformmessagelog/getapplicantfrommessagelogbysystem?';
    static COMPONENT_APPLICANT_MESSAGE_LOG = 'v1/applicantformmessagelog/getapplicantfrommessagelogbyapplicant?';
    static COMPONENT_SAVE_MESSAGE = 'v1/applicantformmessagelog/saveapplicantformmessagelog';
    //Applicant Parent Verification
    static COMPONENT_GENERATE_GUARDIAN_AUTHENTICATION_CODE = 'v1/applicantparentverification/generateguardianauthenticationcode';
    static COMPONENT_VALIDATE_GUARDIAN_AUTHENTICATION_CODE = 'v1/applicantparentverification/validateguardianauthenticationcode';
    //API Generate
    static COMPONENT_APPLICANT_FORMS_GET_APPLICANT_LIST = 'v1/applicantforms/getapplicantlist'
    static COMPONENT_GET_APPLICANT_DETAILS_EXPORT ='v1/applicantforms/applicantdetailsexport'
    static COMPONENT_STUDENT_INFO_SAVE = 'v1/studentinfo/savestudentinfo'
    static COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY = "v1/state/getstatesbycountrycode?countrycode=";
    static COMPONENT_RESOURCE_GET_COUNTY_BY_STATE = 'v1/state/getcountybystatename?statename=';
    static COMPONENT_GET_DYNAMIC_DATA_EXPORT = "v1/dataexport/getdynamicdataexport";
    static COMPONENT_GET_EXPORT_DATA = "v1/dataexport/dataexport";
    static COMPONENT_SELECT_FORM = "v1/applicationform/getallapplicationformbyschoolsessiongrade?";
    //
    static COMPONENT_ADMIN_BULK_PROCESS = "v1/applicantforms/bulkupdateadminactionstatus";
    static GENERATE_PAYMENT_RETPORT = "v1/applicantforms/getapplicantpaymentreport";
    static GENERATE_STATUS_REPORT = "v1/applicantforms/getapplicantprimarycontactreport"
    static DISASSOCIATE_FEE = "v1/applicationformsdetailwisefee/deleteassociatedapplicationformsfee";
    //IMPORT
    static IMPORT_STUDENT_COMMON_API = "v1/import/importdatainfo";
    static IMPORT_STAGED_DATA_GETALL = "v1/import/getimportstagedataall";
    static GET_IMPORT_STAGED_DATA = "v1/import/getimportstagedata";
    static IMPORT_APPLICANT = 'v1/import/importapplicant';
    //Set Default Values
    static COMPONENT_SET_DEFAULT_VALUE_COUNTRY_LIST = "v1/country/getcountrylist";
    static COMPONENT_SET_DEFAULT_VALUE_GET_DEFAULT_COUNTRYSTATE = "v1/countrystate/getcountrystatelist?";
    static COMPONENT_SET_DEFAULT_VALUE_SAVE_COUNTRYSTATE = "v1/countrystate/savecountrystate";
    static COMPONENT_SET_DEFAULT_VALUE_UPDATE_COUNTRYSTATE = "v1​/countrystate​/updatecountrystate";
    static COMPONENT_SET_DEFAULT_VALUE_DELETE_COUNTRYSTATE = "v1/countrystate/deletecountrystate";
    static VALIDATE_IMPORT = "v1/import/validateimportstagedata";
    static DELETE_IMPORT_HEADER = "v1/import/deleteimportheaderdata";
    static DELETE_IMPORT_CHILD = "v1/import/deleteimportchilddata";
    //
    static GET_SIS_EXPORT_DETAILS = "v1/applicantforms/getapplicantsdetailsofseatosis";
    static UPDATE_APPLICATION_STATUS_EXPORT = "v1/applicantforms/updateapplicationadminstatusforexport";
    static GET_LANGUAGE_BY_COUNTRY_CODE = 'v1/language/getlanguagebycountrycode?countrycode=';
    static GET_PREVIEW_URL_RESOURCE = "v1/resource/getpresignedurlofafile?fileInfoId=";
    //
    static COMPONENT_DOWNLOAD_FEE_RECEIPT = "v1/applicantforms/downloadfeereceipts?ApplicantFormId=";
    //upload template
    static COMPONENT_UPLOAD_TEMPLATE_SAVE = "v1/customertemplate/savecustomertemplate";
    static COMPONENT_UPLOAD_TEMPLATE_LIST_BY_CUST_ID = "v1/customertemplate/getbycustomerid?customerId=";
    static COMPONENT_UPLOAD_TEMPLATE_DELETE = "v1/customertemplate/deletecustomertemplate";
    // Grade Level
    static COMPONENT_ENROLLMENT_GRADELEVEL_BYSCHOOLNAME = "v1/gradelevel/getallgradelevelbyschooltitle?schoolName=";
}
export default endPointConfig;