import { Route } from 'react-router-dom';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sharedActionCreators } from '../components/shared/store/sharedAction';
import asyncComponent from './asyncComponent';
import sessionStore from '@markerspro/session-store';
const routeList = [
    'applicantDashBoard',
    'standingInstruction',
    'applicantFormIntro',
    'applicantForm',
    'applicantMessage',
    'immunizationInfo',
];
const adminRouteList = [
    'dashboard',
    'formCreation',
    'setupStatusMessage',
    'applicantProcessing',
    'processNewApplicant',
    'processNewApplicantMessage',
    'formSetup/generalInfo',
    'formSetup/identifier',
    'formSetup/studentContacts',
    'formSetup/visa',
    'formSetup/health',
    'formSetup/birthInfo',
    'formSetup/document',
    'formSetup/immunizationInfo',
    'formSetup/specialProgram',
    'systemIdentification',
    'dynamicForm',
    'setupDynamicForm',
    'dynamicFormAssociation',
    'apiGenerate',
    'fee',
    'feeAssociation',
    'gatewayIntegration',
    'lotterySetup',
    'conductLottery',
    'importAndExport',
    'viewImport',
    'setDefaultValues',
    'sisExportStatus',
    'completeImport',
    'uploadTemplate',
    'classMaster',
    'ageMaster'
];
let routeState = {};
let adminRouteState = {};
for (let i = 0; i < routeList.length; i++) {
    routeState = {
        ...routeState,
        [routeList[i]]: asyncComponent(() =>
            import(`../components/view/${routeList[i]}/homeComponent`).then(module => module.default)
        )
    }
}
for (let i = 0; i < adminRouteList.length; i++) {
    adminRouteState = {
        ...adminRouteState,
        [adminRouteList[i]]: asyncComponent(() =>
            import(`../components/view/${adminRouteList[i]}/homeComponent`).then(module => module.default)
        )
    }
}
const MarkersCommonRoute = asyncComponent(() =>
    import('../components/common/component/markersCommonRoute').then(module => module.default)
);
export class PrivateRoute extends Route {
    render() {
        //MEA related changes...
        // if (new sessionStore().returnSessionToken() == null) {
        //     // return <Redirect to="/sso" />;
        //     window.location.href = hostArray[new sessionStore().returnHostEnvironment()].home + '/sso';
        // }
        //End of MEA related changes...
        return (
            <React.Fragment>
                {routeList.map((route,i) => {
                    return <Route key={i} exact path={`/mea/${route}`} component={routeState[route]} />
                })}
                {adminRouteList.map((route,i) => {
                    return <Route key={"admin"+i} exact path={`/meaAdmin/${route}`} component={adminRouteState[route]} />
                })}
                <Route exact path="/mea/commonRoute" component={MarkersCommonRoute} />
            </React.Fragment>
        )
    }
}
export default connect(
    (state) => { const { sharedStore } = state; return { state: { sharedStore } }; },
    (dispatch) => { return { actions: bindActionCreators(Object.assign({}, sharedActionCreators), dispatch) }; }
)(PrivateRoute)