import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { BaseComponent } from '../shared/BaseComponent';
import { i18n,  sessionStore } from './commonIndex';
class SideBar extends BaseComponent {
    constructor(props) {
        super();
        this.state = {
            relativeUrl: ''
        }
    }

    handleMenuClick = (e, relativeUrl, menuCode) => {
        this.setState({ relativeUrl: relativeUrl })
    }

    getNavLinkClass = (path, exact = true) => {
        if (exact) {
            return this.props.location && this.props.location.pathname === path ? 'active' : '';
        }
        else {
            return ((this.props.location && this.props.location.pathname).indexOf(path) !== -1) ? 'active' : '';
        }
    }

    render() {
        console.log(this.props.a)
        //if (this.state.relativeUrl) {
        //    return <Redirect to={this.state.relativeUrl} />
        //}
        return (
            <React.Fragment>
                <aside className="innerpage-nav preview-inner-nav p-20 bg-canvas border-right">
                    <ul>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageRoutes', '')} className={this.getNavLinkClass("/transport/manageRoutes")}> {i18n.t('generalInfo')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageStop', '')} className={this.getNavLinkClass("/transport/manageStop")}> {i18n.t('identifier')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageRouteDetails', '')} className={this.getNavLinkClass("/transport/manageRouteDetails")}> {i18n.t('documentVerification')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicle', '')} className={this.getNavLinkClass("/transport/manageVehicle")}> {i18n.t('miscellaneousStatus')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageTransportationStaff', '')} className={this.getNavLinkClass("/transport/manageTransportationStaff")}> {i18n.t('refferal')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('visa')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('disability')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('guardian')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('health')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('specialProgram')} </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('dynamicForm')} 1</Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('dynamicForm')} 2</Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(e) => this.handleMenuClick(e, '/transport/manageVehicleRoute', '')} className={this.getNavLinkClass("/transport/manageVehicleRoute")}> {i18n.t('dynamicForm')} 3</Link>
                        </li>
                    </ul>
                </aside>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {

    };
}
const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);