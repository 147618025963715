const currentHost = 'development';
const hostArray = {
    'mp-web-local': {
        home: 'https://localhost:5001',
        transport: 'https://localhost:5006',
        billing: 'https://localhost:5004'
    },
    'mp-web-development': {
        home: 'https://app.development.us.markerspro.com',
        transport: 'https://app-transport.development.us.markerspro.com',
        billing: 'https://app-billing.development.us.markerspro.com'
    },
    'mp-web-qa': {
        home: 'https://app.qa.us.markerspro.com',
        transport: 'https://app-transport.qa.us.markerspro.com',
        billing: 'https://app-billing.qa.us.markerspro.com'
    },
    'mp-web-stage': {
        home: 'https://app.stage.us.markerspro.com',
        transport: 'https://app-transport.stage.us.markerspro.com',
        billing: 'https://app-billing.stage.us.markerspro.com'
    }
}
export {
    currentHost,
    hostArray
}