import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { i18n, endPointConfig, commonConfig, actions, ExecuteArgs, MarkersCommonSelect, sessionStore, SideBranding, Validation, CommonFuncs } from '../commonIndex';
import CryptoENC from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
var SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
const commonFuncObj = new CommonFuncs();
const initialFields = {
    redirectFlag: false,
    authCode: null,
    getCode: false,
    codeErr: 0,
    getCodeFlag: false
}
const saveStateObject = {
    EmailId: "",
    lang: "en",
    isError: 0,
    getDataFlag: false,
    customerKey: "",
    customerId: 0
}

const requiredFields = [
    { fieldName: 'EmailId', fieldType: 'regExp', regExp: commonConfig.EMAIL }
]
const requiredFieldsCodeCheck = [
    { fieldName: 'authCode', fieldType: 'default' }
]
const languageArr = [{ "id": "en", title: "English" }];
//const languageArr = [{ "id": "en", title: "English" }];
let customerId = 0;
let formId = 0;
function ApplyOnline(props) {
    const [stateObject, setStateObject] = useState(initialFields)
    const [fieldObj, setFields] = useState(saveStateObject);
    const [dataObj, setDataObj] = useState([])
    const [redirectFlag, setRedirectFlag] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault();
        const validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(stateObject, requiredFieldsCodeCheck);
        if (submitStatus === 0) {
            setStateObject({ ...stateObject, codeErr: 1 });
        } else {
            setStateObject({ ...stateObject, codeErr: 0, getCodeFlag: true });
            props.onCommonResetProps({ name: 'getValidAuthenticationCode', data: {} });
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_ENROLLMENT,
                endPointConfig.COMPONENT_APPLICANT_VALIDATE_CODE,
                commonConfig.METHOD_TYPE_POST, { emailId: fieldObj.EmailId, verificationCode: stateObject.authCode, id: props.getAuthenticationCode.id, CustomerKey: "", customerId: parseInt(customerId) });
            props.onGetAll(executedArgument, 'validAuthenticationCode');
        }

    }

    useEffect(() => {
        if (props.getConfirmUser && Object.keys(props.getConfirmUser).length > 0 && props.getConfirmUser.isValid) {
            setStateObject({ ...stateObject, getCodeFlag: false, redirectFlag: true })
        }
    }, [props.getConfirmUser])

    useEffect(() => {
        if (window.location.href.indexOf("?") !== -1) {
            var n = sessionStorage.length;
            while (n--) {
                var key = sessionStorage.key(n);
                new sessionStore().cleanSessionStore(key);
            }
            
            let splitUri = window.location.href.split("?");
            new sessionStore().storeSecureData(commonConfig.SESSION_APPLICANT_LOGIN_URL, splitUri[1]);
            let params = window.location.search.split("?");
            params = params[1].split("&");
            for (var i in params) {
                let param = params[i];
                if (!!param) {
                    if (param.includes("cI=")) {
                        customerId = returnParam(param, "cI=") || 0;
                    }
                    if (param.includes("fI=")) {
                        formId = returnParam(param, "fI=") || 0;
                    }
                    if (param.includes("fT=")) {
                        formTypeId = returnParam(param, "fT=") || 1002;
                    }
                }
            }
            function returnParam(data, propertyName) {
                var splitParam = data.split(propertyName);
                if (!!splitParam && splitParam.length > 1 && !!splitParam[1]) {
                    let decodedParam = decodeURIComponent(splitParam[1]);
                    decodedParam = decodedParam.replaceAll(" ", "+")
                    return CryptoAES.decrypt(decodedParam.toString(), SECRET_KEY).toString(CryptoENC);
                }
                else
                    return 0;
            }
        }
    }, [])

    useEffect(() => {
        if (props.getAuthenticationCode && Object.keys(props.getAuthenticationCode).length > 0 && props.getAuthenticationCode.id > 0 && fieldObj.getDataFlag) {
            setStateObject({ ...stateObject, getCode: true })
            setFields({ ...fieldObj, getDataFlag: false });
        }
        if (props.getValidAuthenticationCode && Object.keys(props.getValidAuthenticationCode).length > 0 && props.getValidAuthenticationCode.isValid && stateObject.getCodeFlag) {
            let executedArgument1 = new ExecuteArgs(commonConfig.COMPONENT_ENROLLMENT,
                endPointConfig.COMPONENT_APPLICANT_USER_CONFORMATION + "customerId=" + props.getValidAuthenticationCode.customerId + "&email=" + fieldObj.EmailId + "&MpCustomerId=" + props.getValidAuthenticationCode.mpCustomerId,
                commonConfig.METHOD_TYPE_GET);
            props.onGetAllNew(executedArgument1);
            new sessionStore().storeSecureData(commonConfig.SESSION_LANGUAGE, fieldObj.lang);
            new sessionStore().storeSecureData(commonConfig.SESSION_IS_MP_CUSTOMER, props.getValidAuthenticationCode.isMPCustomer);
            new sessionStore().storeSecureData(commonConfig.SESSION_TOKEN, props.getValidAuthenticationCode.userToken.token);
            new sessionStore().storeSecureData(commonConfig.SESSION_APPLICANT_ID, props.getValidAuthenticationCode.applicantId);
            new sessionStore().storeSecureData(commonConfig.SESSION_APPLICANT_EMAIL, props.getValidAuthenticationCode.userName);
            new sessionStore().storeSecureData(commonConfig.SESSION_ACTIVE_USER_ID, props.getValidAuthenticationCode.activeUserId);
            new sessionStore().storeSecureData(commonConfig.SESSION_DATE_FORMAT, "DD-MM-YYYY")
            new sessionStore().storeSecureData(commonConfig.SESSION_PHONE_FORMAT, "(###) ###-####");
            new sessionStore().storeSecureData(commonConfig.SESSION_CUSTOMER_ID, props.getValidAuthenticationCode.customerId);
            new sessionStore().storeSecureData(commonConfig.SESSION_MP_CUSTOMER_ID, props.getValidAuthenticationCode.mpCustomerId);
            setStateObject({ ...stateObject, getCodeFlag: false })
        }
    }, [props.getAuthenticationCode, props.getValidAuthenticationCode])

    const sendAuthentication = event => {
        event.preventDefault();
        new sessionStore().storeSecureData(commonConfig.SESSION_TOKEN, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9');
        const validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(fieldObj, requiredFields);
        if (submitStatus === 0) {
            setFields({ ...fieldObj, isError: 1 });
        } else {
            setFields({ ...fieldObj, isError: 0, getDataFlag: true });
            props.onCommonResetProps({ name: 'getAuthenticationCode', data: {} });
            if (customerId === 0) {
                props.onShowNotify("Please Use Provided URL!", 'error')
            } else {
                let sentDataObject = Object.assign({
                    EmailId: fieldObj.EmailId,
                    CustomerId: parseInt(customerId),
                    CustomerKey: "",
                })
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_ENROLLMENT,
                    endPointConfig.COMPONENT_APPLICANT_GENERATE_CODE,
                    commonConfig.METHOD_TYPE_POST, sentDataObject);
                props.onGetAll(executedArgument, 'authenticationCode');
            }
        }
    }
    const handleChange = event => {
        let fieldName = event.target.name;
        let fieldValue = null;
        fieldValue = event.target.value;
        if (fieldName === 'authCode') {
            if (commonFuncObj.isValidInt(fieldValue))
                setStateObject({ ...stateObject, [fieldName]: fieldValue })
        }
        else
            setFields({ ...fieldObj, [fieldName]: fieldValue });

    }
    if (redirectFlag) {
        return (<Redirect to="/getCustKey" />)
    }
    if (stateObject.redirectFlag && new sessionStore().returnIsMPCustomer() !== null) {
        let pathname = "";
        if (formId === 0) {
            pathname = "/mea/applicantDashBoard";
        } else {
            if (props.getConfirmUser && Object.keys(props.getConfirmUser).length > 0) {
                if (props.getConfirmUser.isEmailIdPresent) {
                    new sessionStore().storeSecureData(commonConfig.SESSION_ACTIVE_APPLICATION_FORM_ID, formId);
                    new sessionStore().storeSecureData(commonConfig.SESSION_APPLICATION_FORM_ID, formId);
                    pathname = "/mea/applicantDashBoard";
                } else {
                    new sessionStore().storeSecureData(commonConfig.SESSION_ACTIVE_APPLICATION_FORM_ID, formId);
                    new sessionStore().storeSecureData(commonConfig.SESSION_APPLICATION_FORM_ID, formId);
                    pathname = "/mea/applicantDashBoard";
                }
            } else {
                new sessionStore().storeSecureData(commonConfig.SESSION_ACTIVE_APPLICATION_FORM_ID, formId);
                new sessionStore().storeSecureData(commonConfig.SESSION_APPLICATION_FORM_ID, formId);
                pathname = "/mea/applicantDashBoard";
            }

        }
        i18n.changeLanguage(fieldObj.lang);
        return (<Redirect to={pathname} />)
    }
    return (
        <React.Fragment>
            <div className="general-loader">
                <span>
                    <div className="loader-N">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 296.881 258.606">
                            <g id="Group_159" transform="translate(-0.001 -0.001)">
                                <path id="Path_1" d="M651.855,744.721l-63.137,40.948L440.28,689.639v161.22h54.792V790.242l93.534,60.664.111-.171.111.171,63.026-40.876Z" transform="translate(-440.275 -592.299)" fill="#0e67b2" />
                                <path id="Path_2" d="M588.721,748.516l-148.331-96.2-.111.171V717.55l148.331,96.2.111-.172.111.172,93.535-60.664V910.9H737.16V652.491Z" transform="translate(-440.278 -652.315)" fill="#001e33" />
                            </g>
                        </svg>
                    </div>
                </span>
            </div>
            <div className="container custom-container p-t-10 p-b-30">
                <div className="w-100">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <SideBranding {...props} />
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="d-flex align-items-center h-1001">
                                <div className="card w-100 box-shadow-0">
                                    <div className="card-header">
                                        <h3 className="card-title mb-0"><b>{i18n.t('applicationForm')}</b></h3>
                                    </div>
                                    <div className="card-body scroll-card-body">
                                        <div className="panel-default">
                                            <div className="panel-header border-bottom">
                                                <h5 className="m-b-5">{i18n.t('authentication')}</h5>
                                                <p className="mb-0 text-muted f-s-13">{i18n.t('mandatoryFieldsMsg')}</p>
                                            </div>
                                            <div className="panel-body pb-0">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('email')} <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" name="EmailId" value={fieldObj.EmailId || ""} placeholder={i18n.t('enterEmail')} onChange={handleChange} />
                                                            <Validation data={{ field: i18n.t("email"), errorClass: 'email_error', value: fieldObj.EmailId, isError: fieldObj.isError, validationType: 'regExp', regExp: commonConfig.EMAIL }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">

                                                        <MarkersCommonSelect
                                                            label={i18n.t("language")}
                                                            removeFirstOption={true}
                                                            name="lang"
                                                            value={fieldObj.lang || ''}
                                                            optArray={languageArr}
                                                            handleChange={handleChange}
                                                            isError={fieldObj.isError}
                                                            isRequired={false}
                                                        />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <Link to="#" className="btn btn-primary m-t-20" onClick={(e) => sendAuthentication(e)}>{i18n.t('sendAuthenticationCode')}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                {stateObject.getCode ?
                                                    <div className="bg-green-alpha text-default p-10 p-l-15 m-b-20 rounded">
                                                        <i className="icon-checkmark-circle mr-2"></i><b>{i18n.t('authMsgPrt1')}</b> {i18n.t('authMsgPrt2')}
                                                    </div> : null}
                                                {stateObject.getCode ?
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('authenticationCode')} <span>*</span></label>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><i className="icon-lock"></i></span>
                                                                    </div>
                                                                    <input type="text" className="form-control" name="authCode" value={stateObject.authCode || ""} placeholder={i18n.t('enterAuthenticationCode')} onChange={handleChange} maxLength={6} />
                                                                </div>
                                                                <Validation data={{ field: i18n.t("authenticationCode"), errorClass: 'authenticationCode_error', value: stateObject.authCode, isError: stateObject.codeErr, validationType: 'field' }} />
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    {stateObject.getCode ?
                                        <div className="card-footer text-right">
                                            <Link to="#" onClick={(e) => handleSubmit(e)} className="btn btn-success">{i18n.t('submit') + " " + i18n.t('and') + " " + i18n.t('continue')}</Link>
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        getAuthenticationCode: state.commonStore.getAuthenticationCode,
        getValidAuthenticationCode: state.commonStore.getValidAuthenticationCode,
        getConfirmUser: state.commonStore.getConfirmUser
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onGetAll: (executeArgs, title) => dispatch(actions.callCommonGetByApi(executeArgs, title)),
        onCommonResetProps: (executeArgs) => dispatch(actions.commonStoreStateReset(executeArgs)),
        onShowNotify: (message, type) => dispatch(actions.showNotify(message, type)),
        onGetAllNew: (executeArgs) => dispatch(actions.callCommonGetByApi(executeArgs, "confirmUser")),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyOnline);