import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Intercepter from '../security/intercepter';
import Layout from '../components/Layout';
import LoginComponent from '../components/common/pages/loginComponent';
import ApplyOnline from '../components/common/pages/applyOnlineComponent';
import getCustKey from '../components/common/pages/getCustKey';
import styleComponent from './../components/common/styles/styleComponent';
import { translate, Trans } from 'react-i18next';
//import commonConfig from '@markerspro/common-config';
import sessionStore from '@markerspro/session-store';
import DashBoardLayoutComponent from '../components/common/pages/dashboardLayoutComponent';
import ApplicantDashBoardLayoutComponent from '../components/common/pages/applicantDashboardLayoutComponent';
import ClearCache from "react-clear-cache";
import IdleTimer from 'react-idle-timer';
//import i18n from '@markerspro/i18n'
class App extends React.Component {
    constructor(props) {
        super(props)
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
        this.state = {
            URLPARAM: "",
            showLogin:false
        }
    }
    _onIdle() {
        if (new sessionStore().returnSessionToken() !== null) {
            this.setState({ URLPARAM: new sessionStore().returnApplicantLoginUrl(), showLogin:true }) 
        }
    }
    render() {
        if (!!this.state.showLogin) {
            var n = sessionStorage.length;
            while (n--) {
                var key = sessionStorage.key(n);
                sessionStorage.removeItem(key);
            }
        }
        return (
            <div className="App">
                {new sessionStore().returnSessionToken() !== null ?
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onIdle={this.onIdle}
                        debounce={250}
                        timeout={1000 * 60 * 30}
                    // timeout={1000 * 60 * parseInt(new sessionStore().returnIdleTimer(commonConfig.SESSION_IDLETIMER), 10)}
                    /> : null
                }
                <ClearCache auto={true}>
                    {({ isLatestVersion, emptyCacheStorage }) => (
                        <div>{!isLatestVersion && emptyCacheStorage()}</div>
                    )}
                </ClearCache>
                <Intercepter />
                <Layout>
                    <Route path="/" exact component={ApplyOnline} />
                    <Route path="/login" component={LoginComponent} />
                    <Route path="/getCustKey" exact component={getCustKey} />
                    <Route path="/applyOnline" exact component={ApplyOnline} />
                    <Route path="/mea" render={
                        (props) => {
                            if (new sessionStore().returnSessionToken() !== null) {
                                return <ApplicantDashBoardLayoutComponent {...props} />

                            }
                            else {
                                let path = location.protocol + "//" + location.host + "/applyOnline?" + this.state.URLPARAM
                                window.location.href = path;
                            }
                        }
                    } />
                    <Route path="/meaAdmin" render={
                        (props) => {
                            if (new sessionStore().returnSessionToken() !== null) {
                                return <DashBoardLayoutComponent {...props} />
                            }
                            else {
                                let path = location.protocol + "//" + location.host + "/login"
                                window.location.href = path;
                            }
                        }
                    } />
                </Layout>
            </div>
        );
    }
}
export default withRouter(translate('common')(App))