import React from 'react';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import endPointConfig from '../commonEndPointConfig';
import { i18n, commonConfig, actionsCommon, ExecuteArgs, Validation, CommonFuncs } from '../commonLoginindex';
// import  from './validationComponent';
// import CommonFuncs from './markersCommonFunctionComponent';
import { isEqual, remove, findIndex } from 'lodash';
const commonFuncObj = new CommonFuncs();
class MarkersFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.state = {
            id: !!this.props.fileId ? this.props.fileId : null,
            fileList: [],
            selectedFileList: [],
            file: null,
            seletedFileName: '',
            loading: false,
            proccesing: false,
            url: null,
            crop: {
                height: 250,
                width: 250,
                x: 0,
                y: 0
            },
            croppedImage: {},
            cropping: false,
            downloadFlag: false,
            removeFlag: false,
            removeFileId: null,
            savedFileList: props.savedFileList && props.savedFileList.length > 0 ? props.savedFileList : []
        }

    }
    componentWillUnmount() {
        this.props.onResetProps({ name: 'fileDelete', data: false });
    }
    componentWillReceiveProps(nextProps) {
        //if (this.props.hasOwnProperty('savedFileList') && nextProps.savedFileList && !isEqual(nextProps.savedFileList, this.props.savedFileList)) {
        //window.$('[data-toggle="tooltip"]').tooltip();
        //}
        if (nextProps.getFileUrl && !isEqual(nextProps.getFileUrl, this.props.getFileUrl) && this.state.downloadFlag) {
            this.setState({ downloadFlag: false }, () => {
                commonFuncObj.download(nextProps.getFileUrl.fileName, nextProps.getFileUrl.contentType, nextProps.getFileUrl.fileContent);
                this.props.onResetProps({ name: 'getFileUrl', data: {} });
            })
        }
        if (nextProps.clearFile) {
            this.setState({ selectedFileList: [], fileList: [], selectedFileName: "", selectFileExtention: "" });
        }
        if (nextProps.fileDelete && this.state.removeFlag) {
            let fileList = [...this.state.fileList];
            let savedFileList = [...this.state.savedFileList];
            remove(fileList, (object) => { return !!object.id ? object.id : object.fileId === this.state.removeFileId });
            remove(savedFileList, (object) => { return !!object.id ? object.id : object.fileId === this.state.removeFileId });
            this.setState({ removeFlag: false, fileList: fileList, savedFileList: savedFileList, selectedFileList: savedFileList }, () => {
                this.props.onResetProps({ name: 'fileDelete', data: false });
                if (!!this.props.setFileField) {
                    this.props.setFileField(this.state.selectedFileList)
                }
            })
        }
        if (nextProps.savedFileList && Array.isArray(nextProps.savedFileList) && !isEqual(nextProps.savedFileList, this.props.savedFileList)) {
            let savedFileList = nextProps.savedFileList;
            this.setState({ savedFileList });
        }
    }
    /* Starting crop */
    onImageLoaded = (image) => {
        this.imageRef = image;
    }
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    }
    onCropChange = crop => {
        this.setState({ crop, cropping: true });
    }
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.selectedFileName
            );
            this.setState({ croppedImage });
        }
    }
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )
        return new Promise((resolve, reject) => {
            //let dataURL = canvas.toDataURL("image/jpeg");
            //resolve(dataURL);
            canvas.toBlob(
                (blob) => {
                    blob.name = fileName;
                    resolve(blob);
                },
                "image/" + this.state.selectFileExtention,
                1
            );
        })
    }
    handleUpdateProfilePicture = () => {
        if (this.state.cropping && !!this.state.croppedImage && Object.keys(this.state.croppedImage).length > 0) {
            let selectedFileList = new Array();
            const newImage = new File([this.state.croppedImage], this.state.croppedImage.name, {
                type: this.state.croppedImage.type
            });
            selectedFileList.push(newImage);
            this.setState({
                selectedFileList: selectedFileList, proccesing: false, url: null, crop: {
                    height: 250,
                    width: 250,
                    x: 0,
                    y: 0
                }
            }, () => {
                if (!!this.props.setFileField) {
                    window.$("#imageCroperModal").modal('hide')
                    if (this.props.hasOwnProperty('currentIndex') && !!this.props.currentIndex)
                        this.props.setFileField(this.state.selectedFileList, this.props.currentIndex)
                    else
                        this.props.setFileField(this.state.selectedFileList)
                }
                this.generateFileUrl();
            })
        }
        else {
            this.setState({
                proccesing: false, url: null, crop: {
                    height: 250,
                    width: 250,
                    x: 0,
                    y: 0
                }
            }, () => {
                window.$("#imageCroperModal").modal('hide')
            })
        }
    }
    getFile = (event, id) => {
        event.preventDefault();
        this.setState({ downloadFlag: true }, () => {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_ENROLLMENT,
                endPointConfig.COMPONENT_DOWNLOAD_FILE + parseInt(id, 10),
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetFile(executedArgument);
        })
    }
    /* Ending crop */
    handleImageChange = (e) => {
        if (e.target.files.length !== 0) {
            let pathSplitted = e.target.files[0].name.split('.');
            let extension = pathSplitted.pop();
            this.setState({ selectedFileList: e.target.files, cropping: false, selectedFileName: e.target.files[0].name, selectFileExtention: extension }, () => {
                if (!this.props.isMultiple && !!this.props.isCrop) {
                    window.$("#imageCroperModal").modal('show');
                    const reader = new FileReader();
                    reader.addEventListener("load", () =>
                        this.setState({ url: reader.result })
                    );
                    reader.readAsDataURL(this.state.selectedFileList[0]);
                }
                if (!!this.props.setFileField) {
                    if (this.props.hasOwnProperty('currentIndex') && !!this.props.currentIndex)
                        this.props.setFileField(this.state.selectedFileList, this.props.currentIndex)
                    else
                        this.props.setFileField(this.state.selectedFileList)
                }
                this.generateFileUrl();
            })
        }
    }
    generateFileUrl = () => {
        this.setState((prevState, props) => {
            let fileListLength = prevState.selectedFileList.length;
            let previewFileList = [];
            if (fileListLength > 0) {
                for (let i = 0; i < fileListLength; i++) {
                    let url = URL.createObjectURL(prevState.selectedFileList[i])
                    if (prevState.selectedFileList[i].type.indexOf('image') === -1) {
                        let pathSplitted = prevState.selectedFileList[i].name.split('.');
                        let extension = pathSplitted.pop();
                        extension = extension === 'csv' ? 'xlsx' : extension
                        //url = process.env.PUBLIC_URL + '/assets/images/file_type/' + extension + '.svg';
                        url = './assets/images/file_type/' + extension + '.svg'
                    }
                    previewFileList.push({ id: 'file' + (i + 1), name: prevState.selectedFileList[i].name, thumbnailImage: url, size: prevState.selectedFileList[i].size });
                }
            }
            return { fileList: previewFileList }
        });
    }
    removeFile = (event, fileId) => {
        event.preventDefault();
        if (isNaN(fileId)) {
            let List = this.state.savedFileList.concat(this.state.fileList);
            const fileIndex = findIndex(List, { 'id': fileId })
            if (fileIndex !== -1) {
                let fileList = [...this.state.fileList]
                List = [...List];
                let selectedFileList = [...this.state.selectedFileList]
                List.splice(fileIndex, 1);
                fileList.splice(fileIndex, 1)
                selectedFileList.splice(fileIndex, 1)
                this.setState({ removeFlag: false, fileList, selectedFileList }, () => {
                    window.$('#fileupload').trigger('reset');
                    if (!!this.props.setFileField) {
                        this.props.setFileField(List)
                    }
                })
            }
        } else {
            this.props.isDeleteFile(true, parseInt(fileId, 10))
            if (this.props.announcementId === 0 || this.props.announcementId === undefined) {
                this.setState({ removeFlag: true, removeFileId: parseInt(fileId, 10) }, () => {
                    let executedArgument = new ExecuteArgs(this.props.deleteApiComponent,
                        this.props.deleteApiEndPoint, commonConfig.METHOD_TYPE_POST, parseInt(fileId, 10));
                    this.props.onRemoveFile(executedArgument);
                })
            } else {
                this.setState({ removeFlag: true, removeFileId: parseInt(fileId, 10) }, () => {
                    let executedArgument = new ExecuteArgs(this.props.deleteApiComponent,
                        this.props.deleteApiEndPoint, commonConfig.METHOD_TYPE_POST, { "fileId": Number(fileId), "announcementId": this.props.announcementId });
                    this.props.onRemoveFile(executedArgument);
                })
            }
        }
    }
    render() {
        const { label, isError, isRequired, onlyFile, isPreview, isCrop, isMultiple, fileType, isDownload, fileMaxSize, fileMinSize, isDelete, isValidMultiFileSize, disabled } = this.props;
        const fileList = !!isMultiple ? this.state.savedFileList.concat(this.state.fileList) : (this.state.fileList.length > 0 ? this.state.fileList : this.state.savedFileList);
        return (
            <React.Fragment>

                {!!onlyFile ?
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" name="file" multiple={!!isMultiple} onInput={this.handleImageChange} onClick={(event) => {
                                event.target.value = null
                            }} disabled={disabled ? true : false} />
                            <label className="custom-file-label text-truncate" htmlFor="inputGroupFile01">{fileList.length > 1 ? fileList.length + ' files selected' : fileList.length === 1 ? fileList[0].name : i18n.t('selectFile')}{!!fileType ? `(Only '${fileType.replaceAll('|', ', ')}' file type(s) is accepted)` : ''}</label>
                        </div>
                    </div>
                    :
                    <div className="form-group">
                        <label className="col-form-label">{!!label ? label : i18n.t('file')}{isRequired && <span> *</span>}{!!fileType ? `(Only '${fileType.replaceAll('|', ', ')}' file type(s) is accepted)` : ''}</label>
                        <div className="input-group">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" name="file" multiple={!!isMultiple} onInput={this.handleImageChange} onClick={(event) => {
                                    event.target.value = null
                                }} disabled={disabled ? true : false} />
                                <label className="custom-file-label text-truncate" htmlFor="inputGroupFile01">{fileList.length > 1 ? fileList.length + ' files selected' : fileList.length === 1 ? fileList[0].name : i18n.t('selectFile')}</label>
                            </div>
                        </div>
                        <Validation data={{
                            field: !!label ? label : i18n.t('file'),
                            isValidMultiFileSize: !!isValidMultiFileSize,
                            errorClass: 'file_error',
                            value: fileList && fileList.length > 0 ? fileList : [],
                            isError: isError,
                            validationType: !!isRequired ? 'file' : 'onlyFileType',
                            fileType: fileType,
                            fileMaxSize: fileMaxSize,
                            fileMinSize: fileMinSize
                        }} returnValidation={this.functionValidation} />
                    </div>
                }
                {(!!isPreview && fileList.length > 0) &&
                    fileList.map((file, index) => {
                        let imgType = ['jpeg', 'jpg', 'bmp', 'gif', 'png'];
                        let currentFileType = !!file.name ? file.name.split(".").pop().toLowerCase() : '';
                        let find = imgType.includes(currentFileType);
                        return fileType.toLowerCase().indexOf(currentFileType) !== -1 ? <div className={find ? 'preview-image m-b-10 m-r-5 d-inline-block' : 'preview-image m-b-10 m-r-5 d-inline-block preview-icon'} id={!!file.id ? `file-preview-div-${file.id}` : `file-preview-div-${file.fileId}`} data-toggle="tooltip" data-placement="top" title={file.name} key={index}>
                            {!!isDelete && <Link to="#" onClick={(e) => this.removeFile(e, !!file.id ? file.id : file.fileId)} className="deletePreImg"><i className="icon-cancel-circle2"></i></Link>}<img src={file.thumbnailImage} alt={file.name} className="img-fluid" />
                            <span className="preview text-truncate">
                                {!!isDownload && <Link to="#" onClick={(e) => this.getFile(e, !!file.id ? file.id : file.fileId)} className="text-white mr-1"><i className="icon-download"></i></Link>}
                                {file.name}
                            </span>
                        </div> : ''
                    })
                }
                {!!label && label === "Logo" && isCrop === true ?
                    <div className="modal fade" id="imageCroperModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                    {this.state.url && (
                                        <ReactCrop
                                            src={this.state.url}
                                            crop={this.state.crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}
                                </div>
                                <div className="modal-footer">
                                    {this.state.proccesing ?
                                        <button type="button" className="btn btn-primary" disabled={true}>{i18n.t('proccesing')}</button> :
                                        <button type="button" className="btn btn-primary" onClick={() => this.handleUpdateProfilePicture()}>{i18n.t('upload')}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    (!isMultiple && !!isCrop && (!!fileType && fileType.indexOf(fileList[0].type) !== -1)) &&
                    <div className="modal fade" id="imageCroperModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                    {this.state.url && (
                                        <ReactCrop
                                            src={this.state.url}
                                            crop={this.state.crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}
                                </div>
                                <div className="modal-footer">
                                    {this.state.proccesing ?
                                        <button type="button" className="btn btn-primary" disabled={true}>{i18n.t('proccesing')}</button> :
                                        <button type="button" className="btn btn-primary" onClick={() => this.handleUpdateProfilePicture()}>{i18n.t('upload')}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }




            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        getFileUrl: state.commonStore.getFileUrl,
        fileDelete: state.commonStore.fileDelete
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetFile: (executeArgs) => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'file url')),
        onRemoveFile: (executeArgs) => dispatch(actionsCommon.callCommonDeleteApi(executeArgs, 'file')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersFileUpload)
