import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { BaseComponent } from '../shared/BaseComponent';
import { i18n } from './commonIndex';
const DEFAULT_IMAGE_URI = "https://www.pngitem.com/pimgs/m/10-106042_male-professional-avatar-icon-hd-png-download.png";
class CommonHeader extends BaseComponent {
    constructor(props) {
        super();
        this.state = {
            relativeUrl: ''
        }
    }    
    render() {

        return (
            <React.Fragment>
                {
                    
                    this.props.data && Object.keys(this.props.data).length > 0 ? <div className="card-header d-flex align-items-center justify-content-between">
                        <h3 className="card-title mb-0">{!!this.props.title && this.props.title !== 'applicantDashboard' ? <Link to="/mea/applicantDashBoard"><i className="icon-home2 f-s-24 mr-3"></i></Link> : null}<b>{i18n.t(this.props.title)}</b></h3>
                        <div className="d-flex align-items-center mr-2 mt-2">
                            {
                                this.props.data.imageFile ? <img className="rounded-circle mr-2" alt="Photo" width="40" height="40" src={this.props.data.imageFile} />
                                    :
                                    <img className="rounded-circle mr-2" alt="Photo" width="40" height="40" src={DEFAULT_IMAGE_URI} />}<div>
                                <h5 className="m-0 f-s-16 text-default">{this.props.data.name} {this.props.data.code ? "(" +this.props.data.code + ")" : null}</h5>
                                <p className="m-0 f-s-14 text-muted">{this.props.data.gradeLevel}</p>
                            </div>
                        </div>
                    </div>
                        : <div className="card-header d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center"><h3 className="card-title d-flex align-items-center mb-0">{!!this.props.title && this.props.title !== 'applicantDashboard' ? <Link to="/mea/applicantDashBoard" style={{ height: "40px" }}><i className="icon-home2 f-s-24 mr-3"></i></Link> : null}<b>{i18n.t(this.props.title)}</b></h3></div>
                            <span className="d-flex ml-auto"><i className="icon-checkmark-circle mr-2 text-success f-s-24"></i><span className="text-default">{this.props.userEmail}</span></span>
                        </div>
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {

    };
}
const mapDispatchToProps = dispatch => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);