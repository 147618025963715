
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import i18n from '@markerspro/i18n';
import sessionStore from '@markerspro/session-store';
import commonConfig from '@markerspro/common-config';
import CommonFuncs from '@markerspro/common-function'
import _ from 'lodash';
import CryptoAES from 'crypto-js/aes';
import { hostArray } from '../../../hostConfig';
//import CryptoENC from 'crypto-js/enc-utf8';
const commonFuncObj = new CommonFuncs();
var SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
const CHILD_ARRAY_MP = [{ "menuCode": "applicantProcessing", "moduleKey": "FORM_APPOVAL", "title": "applicantProcessing", "relativeUrl": "/meaAdmin/applicantProcessing", "activeRoutes": "/meaAdmin/applicantProcessing", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-loop2", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
{ "menuCode": "exportSIS", "moduleKey": "FORM_APPOVAL", "title": "exportSIS", "relativeUrl": "/meaAdmin/apiGenerate", "activeRoutes": "/meaAdmin/apiGenerate", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-cloud-check", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
{ "menuCode": "sisExportStatus", "moduleKey": "FORM_APPOVAL", "title": "sisExportStatus", "relativeUrl": "/meaAdmin/sisExportStatus", "activeRoutes": "/meaAdmin/sisExportStatus", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-compass", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 }
];
const CHILD_ARRAY_NON_MP = [{ "menuCode": "applicantProcessing", "moduleKey": "FORM_APPOVAL", "title": "applicantProcessing", "relativeUrl": "/meaAdmin/applicantProcessing", "activeRoutes": "/meaAdmin/applicantProcessing", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-loop2", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
{ "menuCode": "exportSIS", "moduleKey": "FORM_APPOVAL", "title": "exportSIS", "relativeUrl": "/meaAdmin/apiGenerate", "activeRoutes": "/meaAdmin/apiGenerate", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-cloud-check", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
{ "menuCode": "import", "moduleKey": "FORM_APPOVAL", "title": "import", "relativeUrl": "/meaAdmin/importAndExport", "activeRoutes": "/meaAdmin/importAndExport", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-copy", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 }
];
//MEA related changes...


class MarkersMenuAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relativeUrl: '',
            redirectStatus: false,
            menuCodes: [
                {
                    activeRoutes: ["/meaAdmin/dashboard"],
                    activeUserId: 0,
                    child: [],
                    description: "Module of MEA",
                    isActive: true,
                    menuClass: "icon-meter",
                    menuCode: "dashboard",
                    moduleKey: "DASH_MEA",
                    moduleName: "DASH_MEA",
                    relativeUrl: "/meaAdmin/dashboard",
                    schoolId: 2076,
                    sortOrder: 231,
                    userId: 1001
                },
                {
                    activeRoutes: ["/meaAdmin/formCreation", "/meaAdmin/dynamicForm", "/meaAdmin/dynamicFormAssociation", "/meaAdmin/fee", "/meaAdmin/feeAssociation", "/meaAdmin/gatewayIntegration"],
                    activeUserId: 0,
                    description: "Module of MEA",
                    isActive: true,
                    menuClass: "add",
                    menuCode: "forms",
                    child: [
                        { "menuCode": "generalForms", "moduleKey": "FORM", "title": "generalForms", "relativeUrl": "/meaAdmin/formCreation", "activeRoutes": "/meaAdmin/formCreation", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-stack-plus", "schoolId": 2076, "userId": 1001, "parentMenuCode": "dynamicForm", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        {
                            "menuCode": "dynamicForms", "moduleKey": "FORM", "title": "dynamicForms", child: [
                                { "menuCode": "creationManagement", "moduleKey": "DIST", "title": "creationManagement", "relativeUrl": "/meaAdmin/dynamicForm", "activeRoutes": "/meaAdmin/dynamicForm", "sortOrder": 1, "isSidebar": "False", "menuClass": "", "schoolId": 2076, "userId": 1001, "parentMenuCode": "dynamicForm", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                                { "menuCode": "association", "moduleKey": "DIST", "title": "Association", "relativeUrl": "/meaAdmin/dynamicFormAssociation", "activeRoutes": "/meaAdmin/dynamicFormAssociation", "sortOrder": 1, "isSidebar": "False", "menuClass": "", "schoolId": 2076, "userId": 1001, "parentMenuCode": "dynamicForm", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 }
                            ], "relativeUrl": "/meaAdmin/formCreation", "activeRoutes": ["/meaAdmin/dynamicForm", "/meaAdmin/dynamicFormAssociation"], "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-stack-text", "schoolId": 2076, "userId": 1001, "parentMenuCode": "dynamicForm", "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0
                        },
                        {
                            "menuCode": "fees", "moduleKey": "FORM", "title": "fees", "relativeUrl": "/meaAdmin/fee", "activeRoutes": ["/meaAdmin/fee", "/meaAdmin/feeAssociation", "/meaAdmin/gatewayIntegration"], "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-cash", "schoolId": 2076, "userId": 1001, "parentMenuCode": "dynamicForm", "child": [
                                { "menuCode": "feeSetup", "moduleKey": "NEW", "title": "FeeSetup", "relativeUrl": "/meaAdmin/fee", "activeRoutes": "/meaAdmin/fee", "sortOrder": 1, "isSidebar": "False", "menuClass": "", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                                { "menuCode": "association", "moduleKey": "NEW", "title": "Association", "relativeUrl": "/meaAdmin/feeAssociation", "activeRoutes": "/meaAdmin/feeAssociation", "sortOrder": 1, "isSidebar": "False", "menuClass": "", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                                { "menuCode": "gatewayIntegration", "moduleKey": "NEW", "title": "GatewayIntegration", "relativeUrl": "/meaAdmin/gatewayIntegration", "activeRoutes": "/meaAdmin/gatewayIntegration", "sortOrder": 1, "isSidebar": "False", "menuClass": "", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 }
                            ], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0
                        },

                    ],
                    moduleKey: "MEA",
                    moduleName: "MEA",
                    relativeUrl: "/meaAdmin/formCreation",
                    schoolId: 2076,
                    sortOrder: 231,
                    userId: 1001
                },
                {
                    activeRoutes: ["/meaAdmin/lotterySetup", "/meaAdmin/conductLottery"],
                    activeUserId: 0,
                    child: [
                        { "menuCode": "lotterySetup", "moduleKey": "Lottery", "title": "lotterySetup", "relativeUrl": "/meaAdmin/lotterySetup", "activeRoutes": "/meaAdmin/lotterySetup", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-dice", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        { "menuCode": "conductLottery", "moduleKey": "Lottery", "title": "conductLottery", "relativeUrl": "/meaAdmin/conductLottery", "activeRoutes": "/meaAdmin/conductLottery", "sortOrder": 1, "isSidebar": "False", "menuClass": "", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 }
                    ],
                    description: "Module of MEA",
                    isActive: true,
                    menuClass: "",
                    menuCode: "lottery",
                    moduleKey: "Lottery",
                    moduleName: "Lottery",
                    relativeUrl: null,
                    schoolId: 2076,
                    sortOrder: 231,
                    userId: 1001
                },
                {
                    activeRoutes: ["/meaAdmin/applicantProcessing", "/meaAdmin/processNewApplicant", '/meaAdmin/processNewApplicantMessage', "/meaAdmin/apiGenerate", "/meaAdmin/sisExportStatus", "/meaAdmin/importAndExport"],
                    activeUserId: 0,
                    child: new sessionStore().returnIsMPCustomer() === true ? CHILD_ARRAY_MP : CHILD_ARRAY_NON_MP,
                    description: "Module of MEA",
                    isActive: true,
                    menuClass: "add",
                    menuCode: "formApproval",
                    moduleKey: "FORM_APPOVAL",
                    moduleName: "FORM_APPOVAL",
                    relativeUrl: "/meaAdmin/applicantProcessing",
                    schoolId: 2076,
                    sortOrder: 231,
                    userId: 1001
                },
                {
                    activeRoutes: ["/meaAdmin/setupStatusMessage", "/meaAdmin/systemIdentification", "/meaAdmin/setDefaultValues", "/meaAdmin/uploadTemplate", "/meaAdmin/classMaster"],
                    activeUserId: 0,
                    child: [
                        { "menuCode": "setupStatusMessage", "moduleKey": "SYS", "title": "setupStatusMessage", "relativeUrl": "/meaAdmin/setupStatusMessage", "activeRoutes": "/meaAdmin/setupStatusMessage", "sortOrder": 1, "isSidebar": "False", "menuClass": "icon-bubble-lock", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        { "menuCode": "systemIdentification", "moduleKey": "SYS", "title": "systemIdentification", "relativeUrl": "/meaAdmin/systemIdentification", "activeRoutes": "/meaAdmin/systemIdentification", "sortOrder": 2, "isSidebar": "False", "menuClass": "icon-equalizer2", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        { "menuCode": "setDefaultValues", "moduleKey": "SYS", "title": "setDefaultValues", "relativeUrl": "/meaAdmin/setDefaultValues", "activeRoutes": "/meaAdmin/setDefaultValues", "sortOrder": 3, "isSidebar": "False", "menuClass": "icon-cog2", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        { "menuCode": "uploadTemplate", "moduleKey": "SYS", "title": "uploadTemplate", "relativeUrl": "/meaAdmin/uploadTemplate", "activeRoutes": "/meaAdmin/uploadTemplate", "sortOrder": 4, "isSidebar": "False", "menuClass": "icon-upload", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        { "menuCode": "classMaster", "moduleKey": "SYS", "title": "classMaster", "relativeUrl": "/meaAdmin/classMaster", "activeRoutes": "/meaAdmin/classMaster", "sortOrder": 5, "isSidebar": "False", "menuClass": "icon-upload", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 },
                        { "menuCode": "ageMaster", "moduleKey": "SYS", "title": "ageMaster", "relativeUrl": "/meaAdmin/ageMaster", "activeRoutes": "/meaAdmin/ageMaster", "sortOrder": 5, "isSidebar": "False", "menuClass": "icon-upload", "schoolId": 2076, "userId": 1001, "parentMenuCode": "fee", "child": [], "activeUserId": 0, "activeUserName": null, "customerId": 0, "reasonForDeletion": null, "isAuditEnable": false, "isSearchEnable": false, "auditParam": { "schema": "", "table": null, "id": 0, "modifiedBy": 0, "modifiedByName": "Admin", "type": 0, "isValid": false }, "isActive": true, "isDeleted": false, "id": 0 }
                    ],
                    description: "Module of MEA",
                    isActive: true,
                    menuClass: "",
                    menuCode: "systemSettings",
                    moduleKey: "SYS",
                    moduleName: "SYS",
                    relativeUrl: "/meaAdmin/setupStatusMessage",
                    schoolId: 2076,
                    sortOrder: 231,
                    userId: 1001
                }
            ]
        }

    }
    //Nav Active inactive.
    getNavLinkClass = (pathArr, path, exact = true) => {
        var isActive = pathArr.includes(path);
        return isActive === true ? 'active' : '';
    }

    collapseClass = (pathArr, path, name = '') => {
        if (!Array.isArray(pathArr)) {
            pathArr = pathArr.split(",");
        }
        var collapse = pathArr.includes(path);
        return collapse === true ? 'collapse show' : 'collapse';
    }

    activeLinkClass = (activeRoute, currentUrl) => {
        return activeRoute === currentUrl ? 'active' : '';
    }
    returnSessionItems() {
        //CryptoAES.decrypt(schoolId.toString(), SECRET_KEY).toString(CryptoENC);
        return 'token=' + new sessionStore().returnSessionToken() + '&l=' + new sessionStore().returnLanguage() + '&sc=' + CryptoAES.encrypt(new sessionStore().returnSchoolID().toString(), SECRET_KEY) +
            '&se=' + CryptoAES.encrypt(new sessionStore().returnSessionID().toString(), SECRET_KEY) + '&d=' + CryptoAES.encrypt(new sessionStore().returnDistrictID().toString(), SECRET_KEY) +
            '&sy=' + CryptoAES.encrypt(new sessionStore().returnSchoolYear().toString(), SECRET_KEY) + '&m=' + CryptoAES.encrypt(new sessionStore().returnMarkingperiodId().toString(), SECRET_KEY) + '&r=' + CryptoAES.encrypt(new sessionStore().returnUserRole().toString(), SECRET_KEY) +
            '&st=' + CryptoAES.encrypt(new sessionStore().returnStaffID().toString(), SECRET_KEY) + '&u=' + CryptoAES.encrypt(new sessionStore().returnUserID().toString(), SECRET_KEY) +
            '&mc=' + CryptoAES.encrypt(new sessionStore().returnCurrentMenuCode(), SECRET_KEY) +
            '&un=' + CryptoAES.encrypt(new sessionStore().returnUserName(), SECRET_KEY) +
            '&ri=' + CryptoAES.encrypt(new sessionStore().returnUserRoleID().toString(), SECRET_KEY) +
            '&ev=' + CryptoAES.encrypt(new sessionStore().returnHostEnvironment().toString(), SECRET_KEY) +
            '&stu=' + CryptoAES.encrypt(new sessionStore().returnStudentID().toString(), SECRET_KEY)
    }

    handleMenuClick = (e, relativeUrl, menuCode) => {
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false, approvalPermission: false, verifyPermission: false, certifyPermission: false, publicationPermission: false };
        let checkCurrentUserRoleBySchool = _.find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        if (!!menuCode && !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') {
            if (menuCode === 'STUD_GENERAL') {
                menuCode = 'STUD_GEN_INFO';
            }
            if (permissionList && permissionList.length > 0) {
                if (permissionList.includes(menuCode + "_VIEW")) {
                    currentPagePermissions.viewPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT")) {
                    currentPagePermissions.editPermission = true;
                }
                if (permissionList.includes(menuCode + "_DELETE")) {
                    currentPagePermissions.deletePermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_APPR")) {
                    currentPagePermissions.approvalPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_VERY")) {
                    currentPagePermissions.verifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_CERT")) {
                    currentPagePermissions.certifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_PUBL")) {
                    currentPagePermissions.publicationPermission = true;
                }
            }
        } else {
            currentPagePermissions.viewPermission = true;
            currentPagePermissions.editPermission = true;
            currentPagePermissions.deletePermission = true;
            currentPagePermissions.approvalPermission = true;
            currentPagePermissions.verifyPermission = true;
            currentPagePermissions.certifyPermission = true;
            currentPagePermissions.publicationPermission = true;
        }
        new sessionStore().removeSessionItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MENU_CODE);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MANAGE_INCIDENT_TYPE);
        new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
        new sessionStore().storeSecureData(commonConfig.SESSION_CURRENT_PAGE_PERMISSION, JSON.stringify(currentPagePermissions));
        if (menuCode === 'DASH_MAIN') {
            relativeUrl = '/dashboard';
        }
        if (new sessionStore().returnRoutePath() !== relativeUrl) {
            window.$(".general-loader").show();
        }
        this.setState({ relativeUrl: relativeUrl, redirectStatus: true });
        //window.$("body").removeClass("ShowRightNav");
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.navbarRedirect && nextState.redirectStatus) {
            this.setState({ redirectStatus: false, relativeUrl: '' });
        }
    }
    render() {
        if (this.state.redirectStatus && !!this.state.relativeUrl) {
            return <Redirect to={this.state.relativeUrl} />
        }
        return (
            <React.Fragment>
                {
                    //MEA related changes...
                    // this.props.navBarList && this.props.navBarList.length > 0 && this.props.navBarList.sort(commonFuncObj.compareValues('sortOrder')) && this.props.navBarList.map((menuValue, index) =>
                    //{this.collapseClass(subMenuValue.activeRoutes, window.location.pathname)}
                    //{this.collapseClass(menuValue.activeRoutes, window.location.pathname, menuValue.moduleName)}
                    this.state.menuCodes.map((menuValue, index) =>
                        menuValue.child.length === 0 ?
                            !!menuValue.menuCode && menuValue.isActive === true ?
                                <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, menuValue.relativeUrl, menuValue.menuCode)} className={this.getNavLinkClass(menuValue.activeRoutes, window.location.pathname)}><i className={menuValue.menuClass + ""}></i> {i18n.t(menuValue.menuCode)}</Link></li >
                                :
                                null
                            :
                            !!menuValue.menuCode && menuValue.isActive === true ?
                                <li key={index} id={'heading' + menuValue.moduleName}><Link data-toggle="collapse" data-target={'#collapse' + menuValue.moduleName} aria-labelledby={'heading' + menuValue.moduleName} to="#" className={this.getNavLinkClass(menuValue.activeRoutes, window.location.pathname)}>{menuValue.moduleName !== "show" ? <i className={menuValue.menuClass + ""}></i> : null} {i18n.t(menuValue.menuCode)}</Link>
                                    <ul id={'collapse' + menuValue.moduleName} className={`collapse ${menuValue.menuClass} ${this.collapseClass(menuValue.activeRoutes, window.location.pathname)}`} aria-labelledby={'heading' + menuValue.moduleName} data-parent="#accordionSidenav">
                                        {menuValue.child && menuValue.child.length > 0 && menuValue.child.sort(commonFuncObj.compareValues('sortOrder')) && menuValue.child.map((subMenuValue, index) =>
                                            subMenuValue.isActive === true && subMenuValue.isSidebar === 'False' ?
                                                subMenuValue.child && subMenuValue.child.length > 0 && subMenuValue.child.sort(commonFuncObj.compareValues('sortOrder')) ?
                                                    <li key={index}>
                                                        <Link to="#" data-toggle="collapse" className={`collapse ${menuValue.menuClass}`} data-target={'#collapse' + subMenuValue.title} aria-labelledby={'heading' + subMenuValue.title}><i className={subMenuValue.menuClass + ""}></i> {i18n.t(subMenuValue.menuCode)}</Link>
                                                        <ul id={'collapse' + subMenuValue.title} className={this.collapseClass(subMenuValue.activeRoutes, window.location.pathname)} aria-labelledby={'heading' + subMenuValue.title}
                                                            data-parent={'#heading' + menuValue.moduleName}>
                                                            {subMenuValue.child.map((subChildMenuValue, index) =>
                                                                <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subChildMenuValue.relativeUrl, subChildMenuValue.menuCode)} className={this.activeLinkClass(subChildMenuValue.activeRoutes, window.location.pathname)}><i className={subChildMenuValue.menuClass + ""}></i> {i18n.t(subChildMenuValue.menuCode)}</Link></li>
                                                            )}
                                                        </ul>
                                                    </li>
                                                    : <li key={index}><Link to="#" onClick={(e) => this.handleMenuClick(e, subMenuValue.relativeUrl, subMenuValue.menuCode)} className={this.activeLinkClass(subMenuValue.activeRoutes, window.location.pathname)}><i className={subMenuValue.menuClass + ""}></i> {i18n.t(subMenuValue.menuCode)}</Link></li>
                                                :
                                                null
                                        )
                                        }
                                    </ul>
                                </li>
                                :
                                null
                    )}
            </React.Fragment>
        )
    }
    //Return sidebar items dynamically.
    //returnSidebarItems(path) {
    //    var sidebarDetails = sidebarList.map((sidebarValue) =>
    //        sidebarValue.isActive === true ? <li data-position={sidebarValue.sortOrder}><Link to={{ pathname: sidebarValue.menuLink }} className={this.getNavLinkClass(sidebarValue.activeRoutes, path)}>{i18n.t(sidebarValue.menuName)}</Link></li> : null
    //    );

    //    return sidebarDetails;
    //}

}
export default MarkersMenuAccess;

