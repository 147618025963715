import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { i18n, endPointConfig, commonConfig, actions, ExecuteArgs, sessionStore, SideBranding, Validation } from '../commonIndex';
let regExpData = /.*/;

function getCustKey(props) {

    let dataObj = {
        custKey: "",
        formId: 0
    }

    const [data, setData] = useState(dataObj);
    const [isError, setIsError] = useState(0);
    const [redirectFlag, setRedirectFlag] = useState(false)

    const handleChange = e => {
        if(e.target.name === "formId") {
            if(e.target.value === "") {
                setData({
                    ...data,
                    [e.target.name] : 0
                })
            }else {
                setData({
                    ...data,
                    [e.target.name] : parseInt(e.target.value)
                })
            }
        }else {
            setData({
                ...data,
                [e.target.name] : e.target.value
            })
        }
        
    }

    const sendCustKey = () => {
        if (data.custKey !== "" && data.formId !== "") {
            setRedirectFlag(true)
        } else {
            setIsError(1)
        }
    }

    if (redirectFlag) {
        return (<Redirect to={{
            pathname: "/applyOnline",
            state: { data: data }
        }} />)
    }

    return (
        <React.Fragment>
            <div className="loaderWrap">
                <div className="loader">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 296.881 258.606">
                        <g id="Group_159" transform="translate(-0.001 -0.001)">
                            <path id="Path_1" d="M651.855,744.721l-63.137,40.948L440.28,689.639v161.22h54.792V790.242l93.534,60.664.111-.171.111.171,63.026-40.876Z" transform="translate(-440.275 -592.299)" fill="#0e67b2" />
                            <path id="Path_2" d="M588.721,748.516l-148.331-96.2-.111.171V717.55l148.331,96.2.111-.172.111.172,93.535-60.664V910.9H737.16V652.491Z" transform="translate(-440.278 -652.315)" fill="#001e33" />
                        </g>
                    </svg>
                </div>
            </div>
            <div className="container p-t-10 p-b-30">
                <div className="w-100">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <SideBranding {...props} />
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="d-flex align-items-center h-1001">
                                <div className="card w-100 box-shadow-0">
                                    <div className="card-header">
                                        <h3 className="card-title mb-0"><b>{i18n.t('applicationForm')}</b></h3>
                                    </div>
                                    <div className="card-body scroll-card-body">
                                        <div className="panel-default">
                                            <div className="panel-body pb-0">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Customer Key<span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" name="custKey" value={data.custKey || ""} placeholder="Enter Customer Key" onChange={handleChange} />
                                                            <Validation data={{ field: "Customer Key", errorClass: 'email_error', value: data.custKey, isError: isError, validationType: 'regExp', regExp: regExpData }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Form ID<span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" name="formId" value={data.formId || ''} placeholder="Enter Form Id" onChange={handleChange} />
                                                            <Validation data={{ field: "Form Id", errorClass: 'email_error', value: data.formId, isError: isError, validationType: 'regExp', regExp: regExpData }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <Link to="#" className="btn btn-success m-t-20" onClick={(e) => sendCustKey()}>{i18n.t('submit')}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        getAuthenticationCode: state.commonStore.getAuthenticationCode,
        getValidAuthenticationCode: state.commonStore.getValidAuthenticationCode
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onGetAll: (executeArgs, title) => dispatch(actions.callCommonGetByApi(executeArgs, title)),
        onCommonResetProps: (executeArgs) => dispatch(actions.commonStoreStateReset(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(getCustKey);